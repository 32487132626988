import Logo from "@Components/Header/Logo"
import useBreakpoints from "@Hooks/useBreakpoints"
import { cn } from "@Utils/theme"

type Props = React.PropsWithChildren<{
  className?: string
  contentClassName?: string
  isSuccess?: boolean
  onSuccessComponent?: React.ReactNode
}>

export const AuthFormCard: React.FC<Props> = ({
  className,
  contentClassName,
  isSuccess = false,
  onSuccessComponent,
  children,
}) => {
  return (
    <div
      className={cn(
        "md:max-w-[48rem] md:w-[48rem] md:mx-auto w-full flex items-center justify-center px-[2rem] pb-[2rem] pt-[7rem] content-center",
        className
      )}
    >
      <div
        className={cn(
          "bg-white rounded-xl p-[1.6rem] shadow-around md:rounded-[2rem] md:px-[5rem] md:py-[3rem] md:pb-[4rem] max-h-fit w-full opacity-0 animate-smoothFadeInUp",
          contentClassName
        )}
      >
        <Logo className="absolute top-[-5rem] left-1/2 -translate-x-1/2" />
        {isSuccess ? onSuccessComponent : children}
      </div>
    </div>
  )
}

export default AuthFormCard
