import { cn } from "@Utils/theme"

export interface FormGroupProps extends React.HTMLAttributes<HTMLDivElement> {}

const FormGroup = ({ className, children, ...props }: FormGroupProps) => {
  return (
    <div className={cn("flex flex-col gap-[1.6rem]", className)} {...props}>
      {children}
    </div>
  )
}

export default FormGroup
