import { IconEye, IconEyeOff, IconX } from "@tabler/icons-react"
import { forwardRef, useCallback, useState, useRef, useEffect } from "react"
import ReactPhoneInput from "react-phone-number-input"
import { cn } from "@Utils/theme"

export interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "prefix"> {
  containerClassName?: string
  togglePassword?: boolean
  clear?: boolean
  onClear?: () => void
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  mask?: "phone"
  onMaskValueChange?: (value: string) => void
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type = "text",
      value,
      autoComplete = "off",
      disabled,
      className,
      /* custom props */
      containerClassName,
      togglePassword,
      clear,
      onClear,
      prefix,
      suffix,
      mask,
      onMaskValueChange,
      /* end custom props */
      ...props
    },
    ref
  ) => {
    const [internalType, setInternalType] = useState(type)
    const [isFocused, setIsFocused] = useState(false)
    const containerRef = useRef<HTMLDivElement>(null)
    const isTogglePassword = togglePassword && type === "password"

    const handleTogglePassword = useCallback(() => {
      setInternalType((prev) => (prev === "password" ? "text" : "password"))
    }, [])

    const handleFocus = useCallback(() => {
      setIsFocused(true)
    }, [])

    const handleBlur = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        props.onBlur?.(event)
        setIsFocused(false)
      },
      [props]
    )

    const handleMaskValueChange = useCallback(
      (value?: string) => {
        onMaskValueChange?.(value || "")
      },
      [onMaskValueChange]
    )

    const getDOMInput = () => {
      // use DOM here instead of ref from props because the ref is coming from outside
      return containerRef.current?.querySelector("input") as HTMLInputElement
    }

    const triggerFocus = useCallback(() => {
      const inputEl = getDOMInput()
      inputEl?.focus()
    }, [])

    useEffect(() => {
      const inputEl = getDOMInput()
      if (inputEl && document.activeElement === inputEl) {
        setIsFocused(true)
      }
    }, [])

    return (
      <div
        ref={containerRef}
        className={cn(
          "relative w-full border-[1px] border-light-600 rounded-lg transition-all duration-200 flex items-center gap-[1rem] px-[1.2rem]",
          disabled ? "bg-gray-100 cursor-not-allowed" : "bg-white",
          isFocused
            ? "border-primary-500 ring-2 ring-primary-500 ring-opacity-50"
            : "hover:border-primary-500",
          containerClassName
        )}
      >
        {prefix && (
          <div
            onClick={triggerFocus}
            className={cn(
              "text-[1.4rem] leading-[2rem] text-primary-600 whitespace-nowrap",
              disabled ? "cursor-not-allowed" : "cursor-default"
            )}
          >
            {prefix}
          </div>
        )}
        {mask === "phone" ? (
          <ReactPhoneInput
            ref={ref as any} // not sure how to fix this
            id={props.id}
            data-testid={props["data-testid"]}
            type={type}
            disabled={disabled}
            value={value ? (value.toString() as any) : ""}
            placeholder={props.placeholder}
            onChange={handleMaskValueChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            defaultCountry="US"
            international
            className={cn(
              "font-[Inter] placeholder:text-gray-400 box-border m-0 py-[0.8rem] text-[1.4rem] leading-[2rem] list-none relative inline-block w-full min-w-0 bg-none rounded-lg text-black disabled:text-gray-300 disabled:cursor-not-allowed",
              className
            )}
          />
        ) : (
          <input
            ref={ref}
            type={isTogglePassword ? internalType : type}
            disabled={disabled}
            value={value}
            autoComplete={autoComplete}
            {...props}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={cn(
              "font-[Inter] placeholder:text-gray-400 box-border m-0 py-[0.8rem] text-[1.4rem] leading-[2rem] list-none relative inline-block w-full min-w-0 bg-none rounded-lg text-black disabled:text-gray-300 disabled:cursor-not-allowed",
              className
            )}
          />
        )}
        {suffix && (
          <div className={cn(disabled ? "cursor-not-allowed" : "cursor-default")}>{suffix}</div>
        )}
        {clear && (
          <div
            role="button"
            tabIndex={0}
            onClick={onClear}
            data-testid={`${props["data-testid"]}-clear`}
            className="absolute top-1/2 -translate-y-1/2 right-[0.8rem] w-[2.2rem] h-[2.2rem] flex items-center justify-center text-gray-300 hover:text-black transition-all duration-200 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-primary-500 focus-visible:ring-opacity-50"
          >
            <IconX size={18} />
          </div>
        )}
        {isTogglePassword && (
          <div
            role="button"
            tabIndex={0}
            onClick={handleTogglePassword}
            data-testid={`${props["data-testid"]}-toggle-password`}
            className="absolute top-1/2 -translate-y-1/2 right-[0.8rem] w-[2.2rem] h-[2.2rem] flex items-center justify-center text-gray-300 hover:text-black transition-all duration-200 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-primary-500 focus-visible:ring-opacity-50"
          >
            {isTogglePassword ? <IconEye size={18} /> : <IconEyeOff size={18} />}
          </div>
        )}
      </div>
    )
  }
)

export default Input
